import React from 'react';
import Layout from '../components/layout';
import logo from '../images/Logo_white@2x.png';
import style from './privacy.module.scss';
import { getKeyFromString } from '../utils/utils.js';

export default props => {
  return (
    <Layout
      // Voisiko nämä kerätä jossain muualla
      // Liikaa yksityiskohtaa tällä abstraktion tasolla
      // ...props tyylisesti
      additionalClassForWrapper="contact-bg"
      headerBgStyles={{
        display: 'flex',
        flexDirection: 'column',
      }}
      headerNavLinkStyle={{ color: 'white' }}
      mainTitle={'Tietosuojaseloste'}
      mainTitleClasses={'mt-5 mb-5'}
      headerTitleStyle={{ color: 'white' }}
      logo={logo}
      logoStyle={{ width: '139px' }}
      {...props}
    >
      <div className={style.privacy + ' ' + 'py-5'}>
        <div class="container">
          <p>
            Tässä dokumentissa on kuvattu seuraavien henkilötietorekisterien
            tietosuojaselosteet:
          </p>
          <ul>
            <li>
              <a href="#uutiskirje">Uutiskirje-rekisteri</a>
            </li>
            <li>
              <a href="#asiakas">Asiakas- ja markkinointirekisteri</a>
            </li>
            <li>
              <a href="#koulutus">Koulutusrekisteri</a>
            </li>
          </ul>
          <p>Kaikkia rekistereissä rekisterinpitäjä on:</p>
          <p>
            Sitemedia / Officekoulutus Visio. Pasuunatie 4b A 22 00420 Helsinki.
            <a href="mailto:officekoulutus@gmail.com">officekoulutus@gmail.com</a>
          </p>
          <p>
            Muut rekistereitä yhteisesti koskevat tiedot on mainittu{' '}
            <a href="#yhteinen">täällä</a>. Lisäksi dokumentissa kerrotaan{' '}
            <a href="#cookies">evästeiden käytöstä tällä sivustolla</a>.
          </p>
        </div>
        <hr />
        <div class="uutiskirje">
          <div class="container">
            <p>
              <a name="uutiskirje"></a>
            </p>
            <h1 id="uutiskirje-rekisteri">Uutiskirje-rekisteri</h1>
            <h2 id="rekisterin-nimi">Rekisterin nimi</h2>
            <p>Uutiskirje-rekisteri</p>
            <h2 id="henkil-tietojen-k-sittelyn-tarkoitus">
              Henkilötietojen käsittelyn tarkoitus
            </h2>
            <ul>
              <li>
                Hyödyllisen informaation tuottaminen Officekoulutus.fi-sivuston
                käyttäjille ja potentiaalisille asiakkaille
              </li>
              <li>Officekoulutus Vision palveluiden markkinointi sekä</li>
              <li>Liiketoiminnan kehittäminen ja suunnittelu</li>
              <li>Yhteydenpito</li>
            </ul>
            <h2 id="rekisterin-tietosis-lt-">Rekisterin tietosisältö</h2>
            <ul>
              <li>Sähköposti</li>
              <li>Etunimi</li>
            </ul>
            <h2 id="s-nn-nmukaiset-tietol-hteet">
              Säännönmukaiset tietolähteet
            </h2>
            <ul>
              <li>Henkilöiden suostumuksella henkilöiltä itseltään</li>
            </ul>
            <h2 id="tietojen-s-ilytysaika">Tietojen säilytysaika</h2>
            <ul>
              <li>
                Uutiskirjeen tilannut käyttäjä saa uutiskirjeitä niin kauan kun
                käyttäjä kuuluu Uutiskirje-rekisteriin.
              </li>
              <li>
                Käyttäjä voi poistua Uutiskirje-rekisteristä omatoimisesti koska
                vain klikkaamalla uutiskirjeen lopussa olevaa linkkiä.
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div class="asiakas">
          <div class="container">
            <p>
              <a name="asiakas"></a>
            </p>
            <h1 id="asiakas-ja-markkinointirekisteri">
              Asiakas- ja markkinointirekisteri
            </h1>
            <h2 id="rekisterin-nimi">Rekisterin nimi</h2>
            <p>Asiakas- ja markkinointirekisteri</p>
            <h2 id="henkil-tietojen-k-sittelyn-tarkoitus">
              Henkilötietojen käsittelyn tarkoitus
            </h2>
            <ul>
              <li>Yhteydenpito asiakkaaseen</li>
            </ul>
            <h2 id="rekisterin-tietosis-lt-">Rekisterin tietosisältö</h2>
            <ul>
              <li>Henkilön nimi</li>
              <li>Henkilön sähköpostiosoite</li>
              <li>Mahdollisesti henkilön yritys</li>
              <li>Yhteydenoton aihe</li>
            </ul>
            <h2 id="s-nn-nmukaiset-tietol-hteet">
              Säännönmukaiset tietolähteet
            </h2>
            <ul>
              <li>
                Pääosin asiakkaat antavat tietonsa itse
                Officekoulutus.fi-sivustolla olevien lomakkeiden välityksellä
              </li>
              <li>
                Tietoja voidaan kerätä myös erilaisten
                markkinointitoimenpiteiden kuten tapahtumien yhteydessä.
              </li>
              <li>
                Lisäksi on mahdollista, että tietoja kerättäisiin ja
                päivitettäisin lisäksi Officekoulutus Vision
                yhteistyökumppaneiden rekistereistä sekä henkilötietoja koskevia
                palveluja tarjoavilta viranomaisilta ja yrityksiltä.
              </li>
            </ul>
            <h2 id="tietojen-s-ilytysaika">Tietojen säilytysaika</h2>
            <p>
              Tietoja säilytetään asiakassuhteen päättymisentai viimeisen
              markkinointitoimenpit een jälkeen kaksi vuotta.
            </p>
          </div>
        </div>
        <hr />
        <div class="koulutus">
          <div class="container">
            <p>
              <a name="koulutus"></a>
            </p>
            <h1 id="koulutusrekisteri">Koulutusrekisteri</h1>
            <h2 id="rekisterin-nimi">Rekisterin nimi</h2>
            <p>Koulutusrekisteri</p>
            <h2 id="henkil-tietojen-k-sittelyn-tarkoitus">
              Henkilötietojen käsittelyn tarkoitus
            </h2>
            <ul>
              <li>Koulutusten suunnittelu asiakkaan tarpeiden perusteella</li>
              <li>Osallistujien jakaminen samantasoisiin ryhmiin</li>
              <li>Koulutuspalautteiden toimittaminen asiakkaalle</li>
              <li>Liiketoiminnan kehittäminen ja suunnittelu</li>
            </ul>
            <h2 id="rekisterin-tietosis-lt-">Rekisterin tietosisältö</h2>
            <ul>
              <li>Osallistujan nimi</li>
              <li>Osallistujan sähköposti</li>
              <li>Tilaajan Laskutusosoite*</li>
            </ul>
            <p>
              *Voi asiakkaasta riippuen olla myös luonnollisen henkilön
              sähköpostiosoite
            </p>
            <h2 id="s-nn-nmukaiset-tietol-hteet">
              Säännönmukaiset tietolähteet
            </h2>
            <ul>
              <li>
                Pääosin asiakkaat antavat tietonsa itse
                Officekoulutus.fi-sivustolla olevien lomakkeiden välityksellä
              </li>
              <li>
                Tietoja voidaan kerätä myös erilaisten
                markkinointitoimenpiteiden kuten tapahtumien yhteydessä.
              </li>
            </ul>
            <h2 id="tietojen-s-ilytysaika">Tietojen säilytysaika</h2>
            <p>
              Tietoja säilytetään koulutuksen päättymisen jälkeen enintään
              kahden vuoden ajan.
            </p>
          </div>
        </div>
        <hr />
        <div class="yhteinen">
          <div class="container">
            <p>
              <a name="yhteinen"></a>
            </p>
            <h1 id="kaikkia-rekistereit-yhteisesti-koskevat-tiedot">
              Kaikkia rekistereitä yhteisesti koskevat tiedot
            </h1>
            <h2 id="tietojen-s-nn-nmukaiset-luovutukset">
              Tietojen säännönmukaiset luovutukset
            </h2>
            <p>
              Käytämme luotettavia palveluntarjoajia henkilötietojen käsittelyn
              tukena muun muassa IT -järjestelmien ylläpito- ja
              kehitystehtävissä.
            </p>
            <p>
              Nämä asiantuntijat käsittelevät henkilötietoja meidän
              toimeksiannostamme. Tietojen käsittelyssä noudatetaan voimassa
              olevaa lainsäädäntöä.
            </p>
            <p>
              Emme luovuta Officekoulutus Vision asiakastietoja palveluiden
              tuotantoon osallistuvien osapuolien ulkopuolelle ilman
              lakisääteistä perustetta.
            </p>
            <p>
              Olemme varmistaneet, että kaikki henkilötietojasi käsittelevät
              tahot noudattavat tietosuojalainsäädäntöä.
            </p>
            <p>
              Seuraavat palveluntarjoajat vastaavat tietojen teknisestä
              käsittelystä ja tallentamisesta
            </p>
            <ul>
              <li>Suomalainen hosting-palveluiden tarjoaja</li>
              <li>Mailchimp (Uutiskirje-palvelu)</li>
              <li>Google (Verkkosivujen analytiikka)</li>
            </ul>
            <h2 id="tietojen-siirto-eu-n-tai-eta-n-ulkopuolelle">
              Tietojen siirto EU:n tai ETA:n ulkopuolelle
            </h2>
            <p>
              Henkilötietoja voidaan käsitellä EU:n ja ETA:n ulkopuolella
              tietojen tietoteknisessä säilyttämis- ja prosessointitarpeessa,
              esimerkiksi käytettäessä pilvipohjaisia sovelluspalveluita.
            </p>
            <p>
              Henkilötietoja käytetään tällöin ainoastaan Officekoulutus Vision
              omaan tarpeeseen, joka on kuvattu tarkemmin tietosuojaselosteen
              kohdassa 4 ”Henkilötietojen käsittelyn tarkoitus ja säilytysaika”.
            </p>
            <h2 id="rekisterin-suojauksen-periaatteet">
              Rekisterin suojauksen periaatteet
            </h2>
            <p>Henkilötietojen turvallinen käsittely on meille tärkeää.</p>
            <ul>
              <li>
                Järjestelmä, jonne tässä dokumentissa mainittujen rekistereiden
                henkilötietoja tallennetaan, on salasanasuojauksen takana.
              </li>
              <li>
                Järjestelmä sisältää eriasteisia käyttöoikeuksia, ja vain tietyt
                ennaltamääritetyt tahot pääsevät katsomaan henkilötietoja.
              </li>
              <li>
                (
                <em>
                  Esimerkiksi kouluttaja pääsee katsomaan esitietolomakkeiden
                  vastauksia voidakseen suunnitella mahdollisimman hyvän
                  koulutuksen ja jakaa osallistujat samantasoisiin ryhmiin.
                </em>
                )
              </li>
              <li>
                Järjestelmä on käyttää SSL-protokollaa liikenteen salaamiseksi
                käyttäjien selaimen ja järjestelmän välillä
              </li>
            </ul>
            <h2 id="rekister-idyn-oikeudet">Rekisteröidyn oikeudet</h2>
            <p>
              <strong>Tarkistusoikeus</strong>
              Henkilöillä on oikeus tarkistaa hänestä tallentamamme
              henkilötiedot. Mikäli henkilö havaitsee tiedoissaan
              virheellisyyksiä tai puutteita, voi hän pyytää meitä korjaamaan
              tai täydentämään tiedot oikeiksi.
            </p>
            <p>
              <strong>Vastustamisoikeus</strong>
              Sinulla on oikeus koska tahansa vastustaa henkilötietojesi
              käsittelyä, mikäli koet, että olemme käsitelleet henkilötietojasi
              lainvastaisesti tai että meillä ei ole oikeutta käsitellä joitain
              henkilötietojasi.
            </p>
            <p>
              <strong>Suoramarkkinointikielto</strong>
              Sinulla on oikeus milloin tahansa kieltää meitä käyttämästä
              tietojasi suoramarkkinointiin. Emme myy tai muutoin luovuta
              henkilötietojasi muille tahoille, jotta nämä voisivat kohdistaa
              suoramarkkinointia sinuun.
            </p>
            <p>
              <em>
                Hankimme verkkomainontaa mm. Googlelta ja Facebookilta. Nämä
                yhtiöt eivät kuitenkaan koskaan saa henkilötietojasi eikä
                tällaisessa mainonnassa ole kyse suoramarkkinoinnista, vaan se
                perustuu evästeisiin. Katso tarkemmin kohta{' '}
                <a href="#cookies">Evästeet</a>
              </em>
              .
            </p>
            <p>
              <strong>Poisto-oikeus</strong>
              Jos koet, että joidenkin sinua koskevien tietojen käsittely ei ole
              tarpeen tehtäviemme kannalta, sinulla on oikeus pyytää meitä
              poistamaan kyseiset tiedot. Käsittelemme pyyntösi, jonka jälkeen
              joko poistamme tietosi tai ilmoitamme sinulle perustellun syyn,
              miksi tietoja ei voida poistaa.
            </p>
            <p>
              Mikäli olet eri mieltä ratkaisustamme, sinulla on oikeus tehdä
              valitus tietosuojavaltuutetulle.
            </p>
            <p>
              Sinulla on myös oikeus vaatia, että rajoitamme kiistanalaisten
              tietojen käsittelyä siksi aikaa, kunnes asia saadaan ratkaistua.
            </p>
            <p>
              <strong>Valitusoikeus</strong>
              Sinulla on oikeus tehdä valitus tietosuojavaltuutetulle, jos koet,
              että rikomme henkilötietojasi käsitellessämme voimassa olevaa
              tietosuojalainsäädäntöä.
            </p>
            <hr />
          </div>
        </div>
        <div class="cookies">
          <div class="container">
            <p>
              <a name="cookies"></a>
            </p>
            <h1 id="tietoja-ev-steist-">Tietoja evästeistä</h1>
            <p>
              Sivustomme käyttää evästeitä. Sivustomme evästeiden avulla kerätty
              tieto on anonyymiä eikä verkossa suoritettuja toimia voida niiden
              avulla liittää tiettyyn henkilöön.
            </p>
            <h2 id="miten-ev-steet-toimivat-">Miten evästeet toimivat?</h2>
            <p>
              Kun vierailet verkkosivuillamme, sivustomme lähettää selaimellesi
              evästeen, joka tallentuu tietokoneellesi.
            </p>
            <p>
              Evästeiden avulla keräämämme tieto voi olla esimerkiksi
              seuraavanlaista:
            </p>
            <ul>
              <li>Joku vieraili verkkosivuillamme 1.4.2018</li>
              <li>Vierailu tapahtui jostainpäin Helsinkiä</li>
              <li>Vierailijan selain oli Firefox</li>
              <li>Vierailija viipyi sivustollamme 7 minuuttia</li>
              <li>Vierailija kävi katsomassa Yhteystiedot-sivua</li>
            </ul>
            <p>
              Emme saa emmekä yritä saada evästeiden kautta sellaista tietoa,
              josta pystyisimme tunnistamaan kuka vierailija oli.
            </p>
            <h2 id="miksi-ker-mme-tietoja-ev-steill-">
              Miksi keräämme tietoja evästeillä?
            </h2>
            <ul>
              <li>
                Keräämme evästeiden avulla statistiikkaa siitä, miten
                vierailijat käyttävät sivustoamme ja miten hyvin sivustomme
                palvelee sivuston vierailijoita.
              </li>
              <li>
                Käytämme tätä tietoa kehittääksemme sivustosta sen
                vierailijoiden näkökulmasta mahdollisimman toimivan, mikä tukee
                myös omaa liiketoimintaamme.
              </li>
              <li>
                Käytämme ajoittain evästeitä markkinoinnin kohdentamiseksi
                vierailijoille, jotka ovat osoittaneet kiinnostusta
                palveluitamme kohtaan.
              </li>
            </ul>
            <h2 id="voit-halutessasi-est-ev-steiden-k-yt-n">
              Voit halutessasi estää evästeiden käytön
            </h2>
            <p>
              Selaimet sallivat evästeiden käytön yleensä oletusarvoisesti. Voit
              estää evästeiden käytön selaimen asetuksista.
            </p>
            <p>Alla olevat linkit toimivat tämän dokumentin luomishetkellä.</p>
            <ul>
              <li>
                <a href="https://support.mozilla.org/fi/kb/evasteiden-paalle-ja-poiskytkeminen">
                  Evästeiden estäminen Firefox-selaimessa
                </a>
              </li>
              <li>
                <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=fi">
                  Evästeiden estäminen Chrome-selaimessa
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};
